
import {Component, Vue, Prop} from 'vue-property-decorator';
import Action from "@/components/Action.vue";
import {RawLocation} from "vue-router/types/router";
import {Getter} from "vuex-class";

@Component({
    name: "End",
    components: {Action},
})
export default class End extends Vue {
    // props

    @Getter("hasConfigurationPhase") hasConfigurationPhase!: boolean;

    // data

    // computed
    get termination(): string {
        if(this.$route.params.termination === "loose") {
            return "loose";
        }
        else {
            return "end";
        }
    }

    get terminationSentence(): string {
        if(this.termination === "end") {
            return "<div><b>BRAVO !</b></div>";
        }
        else {
            return "<div><b>OUPS !</b> <b>Redémarrez</b> vite&nbsp;!</div>"
        }
    }

    get score(): string|null {
        if(this.$store.state.gameState && this.$store.state.gameState.score) {
            return "SCORE: " + this.$store.state.gameState.score;
        }
        else {
            return null;
        }
    }

    get replayRoute(): any {
        if(this.hasConfigurationPhase) {
            return {name: "configure"};
        }
        else {
            return {name: "play"};
        }
    }

    // methods
    redirect(to:RawLocation) {
        this.$router.push(to);
    }

    // watch

    // hooks
    mounted() {
        console.log("END GAME STATE = " + this.$store.state.gameState);
    }

    beforeMount() {
        this.redirect({name: 'home'});
    }
}
